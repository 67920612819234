import { createSlice } from "@reduxjs/toolkit";
import {
  __getEvent,
  __getEventDue,
  __getEventDone,
  __postEvent,
  __putEvent,
  __deleteEvent,
  __getEventCoupon,
  __postEventCoupon,
  __putEventCoupon,
  __deleteEventCoupon,
  __getEventNotice,
  __getPromo,
  __putPromo,
  __getEventResult,
} from "../async/event";
import moment from "moment/moment";
import uuid from "react-uuid";

const initialState = {
  isFetching: false,
  errorMessage: null,
  messages: [],
  messagesId: [],
  eventDue: [],
  eventDone: [],
  eventDueCount: 0,
  eventDoneCount: 0,
  deleteEventId: 0,
  updateEvent: {},
  coupon: [],
  couponPagination: [],
  couponCount: 0,
  updateCouponForm: [],
  updateCouponNum: 0,
  deleteCouponNum: 0,
  inform: [],
  promo: [],
  updateInformNum: 0,
  deleteInformNum: 0,
  productHeight: 1030,
  putEventState: false,
  addFormStartExpDate: "",
  addFormEndExpDate: "",
  resultObject: {},
  checkedUpdate: false,
  checkedConditionTarget: false,
  clickUpdate: false,
  deletePrimaryState: false,
  specifProduct: [],
  eventName: "",
  eventResult: [],
  eventResultCount: 0,
};

const eventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {
    _setDeleteEventId: (state, action) => {
      state.deleteEventId = action.payload;
    },
    _deleteEventDue: (state, action) => {
      const delete_list = state.eventDue.filter(v => {
        return v.id === action.payload ? false : true;
      });
      state.eventDue = delete_list;
    },
    _deleteEventDone: (state, action) => {
      const delete_list = state.eventDone.filter(v => {
        return v.id === action.payload ? false : true;
      });
      state.eventDone = delete_list;
    },
    _updateEventObject: (state, action) => {
      state.updateEvent = action.payload;
    },
    _updateEvent: (state, action) => {
      // console.log(state.eventDue);
      for (let i = 0; i < state.eventDue.length; i++) {
        // console.log(state.eventDue[i].id === Number(action.payload.eventId));

        if (state.eventDue[i].id === Number(action.payload.eventId)) {
          console.log(state.eventDue[i]);
          state.eventDue[i] = action.payload.data;
          console.log(state.eventDue[i]);

          state.eventDue[i].id = Number(action.payload.eventId);
        }
      }
      // console.log(state.eventDue);
    },
    _postCoupon: (state, action) => {
      console.log(action.payload);
      state.coupon.unshift(action.payload);
      state.couponCount = state.coupon.length;
    },
    _updateCoupon: (state, action) => {
      // console.log(action.payload);
      // console.log(state.coupon);
      // console.log(state.coupon[0]);
      // console.log(state.coupon[0].name);

      for (let i = 0; i < state.coupon.length; i++) {
        // console.log(state.coupon);
        // console.log(state.coupon[i]);
        // console.log(action.payload.idx);
        // console.log(action.payload.coupon);

        // console.log(state.coupon[i].id === action.payload.id);

        // if (i === action.payload.idx) {
        //   console.log("test");
        //   state.coupon[i] = action.payload.coupon;
        // }

        if (state.coupon[i].uuid === action.payload.uuid) {
          // console.log("test");
          // console.log(state.coupon[i]);
          // console.log(action.payload);
          state.coupon[i] = action.payload.coupon;
        }
      }

      state.couponCount = state.coupon.length;
    },
    _updateCouponDate: (state, action) => {
      // for (let i = 0; i < state.coupon.length; i++) {
      //   console.log(state.coupon[i].startExpDate);

      //   state.coupon[i].startExpDate = moment(
      //     Date.parse(state.coupon[i].startExpDate),
      //   )
      //     .add(-9, "h")
      //     .format("YYYY-MM-DD HH:mm:ss");

      //   console.log(state.coupon[i].startExpDate);
      //   state.coupon[i].endExpDate = moment(
      //     Date.parse(state.coupon[i].endExpDate),
      //   )
      //     .add(-9, "h")
      //     .format("YYYY-MM-DD HH:mm:ss");
      // }
      for (let i = 0; i < state.coupon.length; i++) {
        console.log(state.coupon[i].startExpDate);

        state.coupon[i].startExpDate = moment(
          Date.parse(state.coupon[i].startExpDate),
        )
          .add(-9, "h")
          .format("YYYY-MM-DD HH:mm:ss");

        console.log(state.coupon[i].startExpDate);

        state.coupon[i].endExpDate = moment(
          Date.parse(state.coupon[i].endExpDate),
        )
          .add(-9, "h")
          .format("YYYY-MM-DD HH:mm:ss");
        console.log(state.coupon[i].endExpDate);

        // state.updateCouponForm.push(state.coupon[i]);
        // console.log(state.updateCouponForm);
      }
    },
    _updateCouponDatePlus: (state, action) => {
      for (let i = 0; i < state.coupon.length; i++) {
        console.log(state.coupon[i].startExpDate);

        state.coupon[i].startExpDate = moment(state.coupon[i].startExpDate)
          .add(9, "h")
          .tz("America/Toronto")
          .utc()
          .format();

        console.log(state.coupon[i].startExpDate);

        state.coupon[i].endExpDate = moment(state.coupon[i].endExpDate)
          .add(9, "h")
          .tz("America/Toronto")
          .utc()
          .format();
        console.log(state.coupon[i].endExpDate);

        // state.updateCouponForm.push(state.coupon[i]);
        // console.log(state.updateCouponForm);
      }
    },
    _deleteCoupon: (state, action) => {
      console.log(action.payload);
      console.log(state.coupon);
      const deleteCoupon_list = state.coupon.filter((v, index) => {
        // return index === action.payload ? false : true;
        return v.uuid === action.payload ? false : true;
      });
      state.coupon = deleteCoupon_list;
    },
    _getCoupon: (state, action) => {},
    _allDeleteCoupon: (state, action) => {
      state.coupon = [];
    },
    _allDeleteInform: (state, action) => {
      state.inform = [];
    },
    _updateCouponNum: (state, action) => {
      console.log(action.payload);
      state.updateCouponNum = action.payload;
    },
    _deleteCouponNum: (state, action) => {
      console.log(action.payload);
      state.deleteCouponNum = action.payload;
    },
    _postInform: (state, action) => {
      state.inform.unshift(action.payload);
    },
    _updateInform: (state, action) => {
      console.log(action.payload);

      for (let i = 0; i < state.inform.length; i++) {
        console.log(state.inform[i].uuid);
        console.log(action.payload.uuid);

        console.log(state.inform[i].uuid === action.payload.uuid);

        if (state.inform[i].uuid === action.payload.uuid) {
          console.log("test");
          state.inform[i] = action.payload;
        }
        // if (i === action.payload.idx) {
        //   state.inform[i] = action.payload.inform;
        // }
      }
    },
    _updateInformNum: (state, action) => {
      console.log(action.payload);
      state.updateInformNum = action.payload;
    },
    _deleteInform: (state, action) => {
      const deleteInform_list = state.inform.filter((v, index) => {
        // return index === action.payload ? false : true;
        return v.uuid === action.payload ? false : true;
      });
      state.inform = deleteInform_list;
    },
    _deleteInformNum: (state, action) => {
      console.log(action.payload);
      state.deleteInformNum = action.payload;
    },
    _productInputHeightConfig: (state, action) => {
      state.productHeight = action.payload;
    },
    _updatePutEventState: state => {
      state.putEventState = false;
    },
    _postAddFormStartExpDate: (state, action) => {
      console.log(action.payload);
      state.addFormStartExpDate = action.payload;
    },
    _postAddFormEndExpDate: (state, action) => {
      console.log(action.payload);
      state.addFormEndExpDate = action.payload;
    },
    _updateResultObject: (state, action) => {
      state.resultObject = action.payload;
    },
    _updateCheckAddUpdate: (state, action) => {
      state.checkedUpdate = action.payload;
    },
    _updateCheckConditionTarget: (state, action) => {
      state.checkedConditionTarget = action.payload;
    },
    _updateCheckClickUpdate: (state, action) => {
      state.clickUpdate = action.payload;
    },
    _checkDeletePrimaryState: (state, action) => {
      state.deletePrimaryState = action.payload;
    },
    _addTargetProd: (state, action) => {
      state.specifProduct = action.payload;
    },
    _deleteTargetProd: (state, action) => {
      state.specifProduct = [];
    },
    _deleteOneProd: (state, action) => {
      // console.log(state.specifProduct.length);
      // const setUp = state.specifProduct.splice(action.payload, 1);
      // state.specifProduct = setUp;
      console.log(typeof state.specifProduct);
      state.specifProduct.splice(action.payload, 1);
    },
    _updateEventName: (state, action) => {
      state.eventName = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      // 이벤트 예정, 완료 조회
      .addCase(__getEvent.fulfilled, (state, action) => {
        console.log(action.payload);
        state.eventDueCount = action.payload.event.due.count;
        state.eventDoneCount = action.payload.event.done.count;
        state.eventDue = action.payload.event.due.rows;
        state.eventDone = action.payload.event.done.rows;
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getEvent.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getEvent.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 예정(페이지네이션) 조회
      .addCase(__getEventDue.fulfilled, (state, action) => {
        console.log(action.payload);
        console.log(action.payload.event.due.rows);

        state.eventDue = action.payload.event.due.rows;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getEventDue.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getEventDue.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 완료(페이지네이션) 조회
      .addCase(__getEventDone.fulfilled, (state, action) => {
        console.log(action.payload);
        state.eventDone = action.payload.event.done.rows;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getEventDone.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getEventDone.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 등록
      .addCase(__postEvent.fulfilled, (state, action) => {
        console.log(action.payload);
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
        // console.log(typeof state.coupon[0].startExpDate);
        // window.location.href = "/event/manage";
      })
      .addCase(__postEvent.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__postEvent.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 수정
      .addCase(__putEvent.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isFetching = false;
        state.errorMessage = null;
        state.putEventState = true;
        // console.log(typeof state.coupon[0].startExpDate);
        state.coupon = [];
        state.inform = [];
      })
      .addCase(__putEvent.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__putEvent.rejected, (state, action) => {
        console.log(state, action);
        console.log(action.payload);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 삭제
      .addCase(__deleteEvent.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__deleteEvent.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__deleteEvent.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 쿠폰 조회
      .addCase(__getEventCoupon.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.coupon = action.payload.coupon.rows;
        state.couponCount = action.payload.coupon.count;
        state.coupon = state.coupon.map(couponData => ({
          ...couponData,
          uuid: uuid(),
        }));
        // console.log(state.coupon);
        // console.log(state.coupon.uuid);
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getEventCoupon.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getEventCoupon.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 공지 조회
      .addCase(__getEventNotice.fulfilled, (state, action) => {
        console.log(action.payload);
        state.inform = action.payload.notice;
        state.inform = state.inform.map(informData => ({
          ...informData,
          uuid: uuid(),
        }));
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getEventNotice.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getEventNotice.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 쿠폰 등록
      .addCase(__postEventCoupon.fulfilled, (state, action) => {
        console.log(action.payload);
        state.messages = action.payload;
        state.couponCount = 0;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postEventCoupon.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__postEventCoupon.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 쿠폰 수정
      .addCase(__putEventCoupon.fulfilled, (state, action) => {
        console.log(action.payload);
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__putEventCoupon.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__putEventCoupon.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 쿠폰 삭제
      .addCase(__deleteEventCoupon.fulfilled, (state, action) => {
        console.log(action.payload);
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__deleteEventCoupon.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__deleteEventCoupon.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getPromo.fulfilled, (state, action) => {
        console.log(action.payload.event);
        state.promo = action.payload.event;

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getPromo.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getPromo.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__putPromo.fulfilled, (state, action) => {
        console.log(action);
        const idx = state.promo.findIndex(pro => {
          return pro.id === action.meta.arg.id;
        });

        state.promo[idx].promotion = action.payload.event.promotion;
        state.promo[idx].guideline = action.payload.event.guideline;

        // state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__putPromo.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__putPromo.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // 이벤트 결과
      .addCase(__getEventResult.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.eventResult = action.payload.event.done.rows;
        state.eventResultCount = action.payload.event.done.count;

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getEventResult.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getEventResult.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      }),
});

export const {
  _setDeleteEventId,
  _deleteEventDue,
  _deleteEventDone,
  _updateEventObject,
  _updateEvent,
  _postCoupon,
  _deleteCoupon,
  _updateCoupon,
  _updateCouponNum,
  _allDeleteCoupon,
  _postInform,
  _updateInform,
  _updateInformNum,
  _deleteCouponNum,
  _deleteInform,
  _deleteInformNum,
  _allDeleteInform,
  _productInputHeightConfig,
  _updatePutEventState,
  _updateCouponDate,
  _postAddFormStartExpDate,
  _postAddFormEndExpDate,
  _updateResultObject,
  _updateCheckAddUpdate,
  _updateCouponDatePlus,
  _updateCheckConditionTarget,
  _updateCheckClickUpdate,
  _checkDeletePrimaryState,
  _addTargetProd,
  _deleteTargetProd,
  _deleteOneProd,
  _updateEventName,
} = eventSlice.actions;
export default eventSlice.reducer;
