import { createAsyncThunk } from "@reduxjs/toolkit";
import { signupApi } from "../../shared/api";
import Swal from "sweetalert2";

export const __signup = createAsyncThunk(
  "/post/signup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await signupApi.signup(data);
      if (response.status === 201) {
        localStorage.setItem("accessToken", response.data.member.accessToken);
        localStorage.setItem("refreshToken", response.data.member.refreshToken);
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __duplicate_check = createAsyncThunk(
  "/post/signup/id",
  async (data, { rejectWithValue }) => {
    try {
      const response = await signupApi.duplicate_check(data);
      return response.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

// 브랜드 중복체크
export const __duplicate_brand = createAsyncThunk(
  "get/duplicate/brand",
  async (data, thunkAPI) => {
    try {
      const res = await signupApi.duplicate_brand(data);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const __postForgetPassword = createAsyncThunk(
  "/post/signup/forgetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await signupApi.postForgetPassword(data);
      console.log(response);
      return response.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __postResetPassword = createAsyncThunk(
  "/post/signup/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await signupApi.postResetPassword(data);
      console.log(response);
      return response.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __getInfo = createAsyncThunk(
  "get/getInfo",
  async (data, thunkAPI) => {
    try {
      console.log(data);
      const res = await signupApi.getInfo(data);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);
