import { createSlice } from "@reduxjs/toolkit";
import {
  __getBannedChat,
  __getChat,
  __getChatId,
  __getChatUnique,
  __putBannedChat,
} from "../async/chat";

const initialState = {
  isFetching: false,
  errorMessage: null,
  messages: [],
  messagesId: [],
  bannedWords: null,
  chatLog: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState: initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      // 로그인
      .addCase(__getChat.fulfilled, (state, action) => {
        console.log(action.payload);
        state.chatLog = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getChat.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getChat.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getChatId.fulfilled, (state, action) => {
        console.log(action.payload);
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getChatId.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getChatId.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getChatUnique.fulfilled, (state, action) => {
        console.log(action.payload);
        state.messagesId = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getChatUnique.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getChatUnique.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getBannedChat.fulfilled, (state, action) => {
        // const str = action.payload.store.filter;
        state.bannedWords = action.payload.store.filter;
        // state.bannedWords = str
        //   .substring(1, str.length - 1)
        //   .replaceAll('"', "")
        //   .replaceAll(/(\s*)/g, "");
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getBannedChat.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getBannedChat.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__putBannedChat.fulfilled, (state, action) => {
        const str = action.payload.store.filter;

        state.bannedWords = str
          .substring(1, str.length - 1)
          .replaceAll('"', "")
          .replaceAll(/(\s*)/g, "");
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__putBannedChat.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__putBannedChat.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      }),
});

export const {} = chatSlice.actions;
export default chatSlice.reducer;
