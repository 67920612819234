import { createAsyncThunk } from "@reduxjs/toolkit";
import { productApi } from "../../shared/api";
import Swal from "sweetalert2";

export const __getDetail = createAsyncThunk(
  "get/getDetail",
  async (idx, thunkAPI) => {
    try {
      const res = await productApi.getDetail(idx);
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.message);
    }
  },
);

export const __postDetail = createAsyncThunk(
  "post/postDetail",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const res = await productApi.postDetail(data);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __putDetail = createAsyncThunk(
  "put/putDetail",
  async (data, { rejectWithValue }) => {
    try {
      const copy = { ...data };
      delete data.productNo;
      const res = await productApi.putDetail(copy.productNo, data);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __getCategory = createAsyncThunk(
  "get/getCategory",
  async (idx, thunkAPI) => {
    try {
      const res = await productApi.getCategory(idx);
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.message);
    }
  },
);

export const __getAllProducts = createAsyncThunk(
  "get/getAllProducts",
  async (data, thunkAPI) => {
    try {
      var res;
      if (typeof data === "undefined" || data === undefined) {
        res = await productApi.getAllProducts();
      } else {
        res = await productApi.getCateProducts(data);
      }
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.message);
    }
  },
);

export const __getEventProducts = createAsyncThunk(
  "get/getEventProducts",
  async (eventIdx, thunkAPI) => {
    try {
      const res = await productApi.getEventProducts(eventIdx);
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.message);
    }
  },
);
