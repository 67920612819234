import { createSlice } from "@reduxjs/toolkit";
import { __getDescription } from "../async/youtube";

const initialState = {
  description: "",
  isFetching: false,
  errorMessage: "",
};

const youtubeSlice = createSlice({
  name: "youtube",
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(__getDescription.fulfilled, (state, action) => {
        if (action.payload) {
          state.description =
            action.payload.items[0].snippet.localized.description;
        }

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getDescription.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getDescription.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      }),
});
export default youtubeSlice.reducer;
