import { createAsyncThunk } from "@reduxjs/toolkit";
import { tutorialApi } from "../../shared/api";
import Swal from "sweetalert2";

export const __getTutorial = createAsyncThunk(
  "get/getTutorial",
  async (data, thunkAPI) => {
    try {
      const res = await tutorialApi.getTutorial();
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.message);
    }
  },
);

export const __doTutorial = createAsyncThunk(
  "post/doTutorial",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const res = await tutorialApi.doTutorial(data);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __getOption = createAsyncThunk(
  "get/getOption",
  async (data, thunkAPI) => {
    try {
      const res = await tutorialApi.getOption();
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.message);
    }
  },
);

export const __offTutorial = createAsyncThunk(
  "put/offTutorial",
  async (data, { rejectWithValue }) => {
    try {
      const res = await tutorialApi.offTutorial();
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);
