import { createSlice } from "@reduxjs/toolkit";
import {
  __getTutorial,
  __doTutorial,
  __getOption,
  __offTutorial,
} from "../async/tutorial";

const init = {
  list: [],
  done: [],
  showState: false,
  isFetching: false,
  errorMessage: null,
};

const tutorialSlice = createSlice({
  name: "tutorial",
  initialState: init,
  reducers: {
    _showTutorial: state => {
      if (localStorage.getItem("tutorial")) {
        state.showState = JSON.parse(localStorage.getItem("tutorial"));
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(__getTutorial.fulfilled, (state, action) => {
        console.log(action.payload);
        state.list = action.payload.tutorial.list;
        state.done = action.payload.tutorial.done;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getTutorial.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__getTutorial.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__doTutorial.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__doTutorial.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__doTutorial.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getOption.fulfilled, (state, action) => {
        console.log(action.payload);
        // state.showState = true;
        if (localStorage.getItem("tutorial")) {
          state.showState = JSON.parse(localStorage.getItem("tutorial"));
        } else {
          localStorage.setItem("tutorial", action.payload.option.tutorial);
          state.showState = action.payload.option.tutorial;
        }

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getOption.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__getOption.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__offTutorial.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__offTutorial.pending, (state, action) => {
        console.log(action.payload);
        state.isFetching = true;
      })
      .addCase(__offTutorial.rejected, (state, action) => {
        console.log(state, action);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      }),
});
export const { _showTutorial } = tutorialSlice.actions;
export default tutorialSlice.reducer;
