import axios from "axios";
import { isExpired } from "react-jwt";

// axios 기본 주소 & header 타입 세팅
export const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_SERVER,
  // timeout: 5000,
});

// axios 기본 주소 & header 타입 세팅
export const instanceAPI = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  // timeout: 5000,
});

// youtube 기본주소
export const youtubeAPI = axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
});

// const youtubeKey = process.env.YOUTUBE_AUTH;

//┏----------interceptor를 통한 header 설정----------┓
instance.interceptors.request.use(async config => {
  config.headers["Content-Type"] = "application/json; charset=utf-8";
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  config.headers["Accept"] = "*/*";

  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) return config;

  if (isExpired(accessToken)) {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post(
        process.env.REACT_APP_AUTH_SERVER + `/auth/token`,
        { refreshToken },
      );
      localStorage.setItem("accessToken", response.data.member.accessToken);
      localStorage.setItem("refreshToken", response.data.member.refreshToken);
      config.headers.Authorization = `Bearer ${response.data.member.accessToken}`;
      return config;
    } catch (error) {
      console.log(error);
      localStorage.clear();
      window.location.replace("/login");
      return config;
    }
  }
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

instanceAPI.interceptors.request.use(async config => {
  config.headers["Content-Type"] = "application/json; charset=utf-8";
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  config.headers["Accept"] = "*/*";
  config.headers["access_token"] = process.env.REACT_APP_REST_AUTH;

  return config;
});

// 채팅 관련 axios API 통신
// chatSlice
export const chatApi = {
  // 채팅 조회하기
  getChat: chatId => instanceAPI.get(`/chat`, { params: { chat_id: chatId } }),
  // 채팅 유저 조회하기
  getChatId: chatId => instanceAPI.get(`/chat/${chatId}`),
  // 채팅방 조회하기
  getChatUnique: () => instanceAPI.get(`/chat/unique`),
  getBannedChat: () => instance.get(`/manage/store/chat`),
  putBannedChat: data => instance.put(`/manage/store/chat`, data),
  test: data => instanceAPI.post(``, data),
};

export const resultApi = {
  postResult: eventId => instanceAPI.post(`/event/dashboard/create/${eventId}`),
};

// 로그인 관련 axios API 통신
// loginSlice
export const loginApi = {
  // 로그인
  login: data => instance.post("/auth/signin", data),
  // 비밀번호 변경
  changePwd: data => instance.post("/auth/password-change", data),
  // 계정조회
  getAccount: () => instance.get("/auth/info"),
  // 계정수정
  putAccount: data => instance.put("/auth/info", data),
  autoLogin: data => instance.post("/auth/auto", data),
};

// 회원가입 관련 axios API 통신
// signupSlice
export const signupApi = {
  // 회원가입
  signup: data => instance.post(`/auth/signup`, data.data),
  // 아이디중복 체크
  duplicate_check: email =>
    instance.get(`/auth/email-check`, { params: { email: email } }),

  duplicate_brand: brand =>
    instance.get("/auth/brand-check", {
      params: { brand: brand },
    }),
  postForgetPassword: email => instance.post("auth/password-forget", email),
  postResetPassword: data => instance.post("auth/password-reset", data),
  getRedirect: url => axios.get(`https://cors-anywhere.herokuapp.com/${url}`),
  getInfo: token => instance.get(`auth/signup`, { params: { token: token } }),
};

// 이벤트 관련 axios API 통신
// eventSlice
export const eventApi = {
  getEvent: data => instance.get(`/manage/event`),
  getEventDue: page =>
    instance.get(`/manage/event/due`, {
      params: { page: page },
    }),
  getEventDone: page =>
    instance.get(`/manage/event/done`, {
      params: { page: page },
    }),
  postEvent: data => instance.post("/manage/event", data),
  putEvent: data => instance.put(`/manage/event/${data.eventIdx}`, data.data),
  deleteEvent: eventId => instance.delete(`/manage/event/${eventId}`),
  getEventCoupon: eventIdx => instance.get(`/manage/event/${eventIdx}/coupon`),
  getEventNotice: eventIdx => instance.get(`/manage/event/${eventIdx}/notice`),
  getPromo: () => instance.get(`/manage/event/promotion`),
  putPromo: (eventIdx, data) =>
    instance.put(`/manage/event/promotion/${eventIdx}`, data),
  getEventResult: brandIdx => instanceAPI.get(`/event/done/${brandIdx}`),
  // postEventCoupon: data =>
  //   instance.post(`manage/event/${data.eventIdx}/coupon`, data.data),
  // putEventCoupon: data =>
  //   instance.put(
  //     `manage/event/${data.eventIdx}/coupon/${data.couponIdx}`,
  //     data.data,
  //   ),
  // deleteEventCoupon: data =>
  //   instance.delete(`/manage/event/${data.eventIdx}/coupon/${data.couponIdx}`),
};

export const serviceApi = {
  getNotice: page => instance.get(`/manage/information?page=${page}`),
  getFaq: page => instance.get(`/manage/question?page=${page}`),
};

export const customerApi = {
  getCustomerList: (type, value) =>
    instance.get(`/manage/customer`, {
      params: { type, value },
    }),
  getBlockCustomerList: () => instance.get(`/manage/customer/block`),
  setBlockCustomer: memberId =>
    instance.post(`/manage/customer/block`, memberId),
  deleteBlockCustomer: data =>
    instance.delete(`/manage/customer/block`, { data }),
};

export const storeApi = {
  getStore: () => instance.get(`/manage/store`),
  postStore: data => instance.post(`/manage/store`, data),
  putStore: data => instance.put(`/manage/store`, data),
  duplicate_domain: domain =>
    instance.get(`/manage/store/domain-check`, {
      params: { domain: domain },
    }),
  getButton: () => instance.get(`/button`),
  postButton: data => instance.post(`/button`, data),
  editButton: data => instance.put(`/button`, data),
  postInstallButton: () => instance.post(`/button/scripttags`),
  deleteButton: () => instance.delete(`/button/remove`),
  getManagerList: () => instance.get(`/manage/store/manager`),
  getCustomerList: (key, value) =>
    instance.get(`/manage/store/manager`, { params: { key, value } }),
  postManager: data => instance.post(`/manage/store/manager`, data),
  deleteManager: memberIdx =>
    instance.delete(`/manage/store/manager/${memberIdx}`),
  getProducts: idx => instance.get(`/manage/product/${idx}`),
  getMap: () => instance.get(`/manage/store/map`),
  getStoreDomain: () => instance.get("/manage/store/domain"),
};

export const tutorialApi = {
  getTutorial: () => instance.get(`/manage/tutorial`),
  doTutorial: data => instance.post(`/manage/tutorial`, data),
  getOption: () => instance.get(`/auth/option`),
  offTutorial: () => instance.put(`/auth/option/tutorial`),
};

export const productApi = {
  postDetail: data => instance.post(`/manage/product/detail`, data),
  getDetail: idx =>
    instance.get(`/manage/product/detail`, { params: { product_no: idx } }),
  putDetail: (productNo, data) =>
    instance.put(`/manage/product/detail/${productNo}`, data),
  getCategory: () => instance.get(`/manage/store/category`),
  getAllProducts: () => instance.get(`/manage/product`),
  getCateProducts: idx => instance.get(`/manage/product/${idx}`),
  getEventProducts: eventIdx =>
    instance.get(`/manage/event/${eventIdx}/product`),
};

export const youtubeApi = {
  getDescription: videoId =>
    youtubeAPI.get(`/videos`, {
      params: {
        part: "snippet",
        id: `${videoId}`,
        key: `${process.env.REACT_APP_YOUTUBE_AUTH}`,
      },
    }),
};
