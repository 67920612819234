import {
  __signup,
  __duplicate_check,
  __duplicate_brand,
  __postForgetPassword,
  __postResetPassword,
  __getInfo,
} from "../async/signup";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetching: false,
  errorMessage: null,

  checkIdDupLoading: false, // 아이디중복 체크 중
  checkIdDupDone: false,
  checkIdDupError: null,
  checkIdDupResult: null,

  checkBrandDupLoading: false, // 브랜드중복 체크 중
  checkBrandDupDone: false,
  checkBrandDupError: null,
  checkBrandDupResult: null,

  queryData: "",
  redirectUrl: "",
  info: { mallId: "", shopName: "", userName: "" },
};

const signUpSlice = createSlice({
  name: "signup",
  initialState: initialState,
  reducers: {
    _postQueryData: (state, action) => {
      state.queryData = action.payload;
    },
    _changeCheckIdDupResultState: (state, action) => {
      state.checkIdDupResult = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      // 회원가입
      .addCase(__signup.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
        console.log(action.payload);
        const data = {
          brand: action.payload.member.brand,
          mallId: state.info.mallId,
        };
        localStorage.setItem("shopData", JSON.stringify(data));
        localStorage.setItem("email", action.payload.member.email);
        localStorage.setItem(
          "member",
          JSON.stringify({
            mallId: state.info.mallId,
            shopName: action.payload.member.brand,
            userName: action.payload.member.name,
          }),
        );
        // localStorage.setItem(
        //   "mall_id",
        //   state.queryData.substr(1).split("&")[1].split("=")[1],
        // );
        window.location.replace("/signupFinish");
      })
      .addCase(__signup.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__signup.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      // checkIdDup
      .addCase(__duplicate_check.pending, state => {
        state.checkIdDupLoading = true;
        state.checkIdDupDone = false;
        state.checkIdDupError = null;
      })
      .addCase(__duplicate_check.fulfilled, (state, action) => {
        console.log(action.payload);
        state.checkIdDupLoading = false;
        state.checkIdDupDone = true;
        state.checkIdDupResult = true;
      })
      .addCase(__duplicate_check.rejected, (state, action) => {
        state.checkIdDupLoading = false;
        state.checkIdDupError = action.payload;
        console.log(action.payload.error);
        console.log(action.payload.error.email);
        if (action.payload.error.email === "Invalid value") {
          console.log("test");
          state.checkIdDupResult = true;
        } else if (
          action.payload.error.message === "This email already in use."
        )
          state.checkIdDupResult = false;
      })
      // 브랜드 중복체크
      .addCase(__duplicate_brand.fulfilled, (state, action) => {
        console.log(action.payload);
        state.checkBrandDupLoading = false;
        state.checkBrandDupDone = true;
        state.checkBrandDupResult = true;
      })
      .addCase(__duplicate_brand.pending, state => {
        state.checkBrandDupLoading = true;
        state.checkBrandDupDone = false;
        state.checkBrandDupError = null;
      })
      .addCase(__duplicate_brand.rejected, (state, action) => {
        state.checkBrandDupLoading = false;
        state.checkBrandDupError = action.payload;
        console.log(action);
        console.log(action.payload.error.message);

        if (action.payload.error.email === "Invalid value") {
          console.log("test");
          state.checkBrandDupResult = true;
        } else if (
          action.payload.error.message === "This brand name already in use."
        )
          state.checkBrandDupResult = false;
      })
      .addCase(__postForgetPassword.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postForgetPassword.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__postForgetPassword.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__postResetPassword.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postResetPassword.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__postResetPassword.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getInfo.fulfilled, (state, action) => {
        if (action.payload.member) {
          state.info = action.payload.member;
        }
        console.log(action.payload);
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getInfo.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getInfo.rejected, (state, action) => {
        console.log(action.payload);
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      }),
});

export const { _postQueryData, _changeCheckIdDupResultState } =
  signUpSlice.actions;
export default signUpSlice.reducer;
