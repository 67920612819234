import { createAsyncThunk } from "@reduxjs/toolkit";
import { storeApi } from "../../shared/api";
import Swal from "sweetalert2";

// 조회
export const __getStore = createAsyncThunk(
  "get/store",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.getStore();
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 등록
export const __postStore = createAsyncThunk(
  "post/store",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.postStore(data);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 조회
export const __putStore = createAsyncThunk(
  "put/store",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.putStore(data);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 조회
export const __duplicate_domain = createAsyncThunk(
  "get/duplicate_domain",
  async (domain, thunkAPI) => {
    try {
      const response = await storeApi.duplicate_domain(domain);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 버튼 관리
// 조회
export const __getButton = createAsyncThunk(
  "get/getButton",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.getButton();
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 등록
export const __postButton = createAsyncThunk(
  "post/postButton",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.postButton(data);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 수정
export const __editButton = createAsyncThunk(
  "put/editButton",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.editButton(data);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 등록
export const __postInstallButton = createAsyncThunk(
  "postInstall/postButton",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.postInstallButton();
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 삭제
export const __deleteButton = createAsyncThunk(
  "delete/deleteButton",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.deleteButton();
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 관리자
// 조회
export const __getManagerList = createAsyncThunk(
  "get/getManagerList",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.getManagerList();
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 검색
export const __getCustomerList = createAsyncThunk(
  "get/getCustomerList",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.getCustomerList(data.key, data.value);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 등록
export const __postManager = createAsyncThunk(
  "post/postManager",
  async (data, thunkAPI) => {
    try {
      console.log(data);
      const response = await storeApi.postManager(data);
      console.log(response);
      if (response.status === 201) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 삭제
export const __deleteManager = createAsyncThunk(
  "delete/deleteManager",
  async (memberIdx, { rejectWithValue }) => {
    try {
      const res = await storeApi.deleteManager(memberIdx);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

// 상품 조회
export const __getProducts = createAsyncThunk(
  "get/getProducts",
  async (data, thunkAPI) => {
    try {
      console.log(data);
      const response = await storeApi.getProducts(data);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// map 조회
export const __getMap = createAsyncThunk(
  "get/getMap",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.getMap();
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 조회
export const __getStoreDomain = createAsyncThunk(
  "get/getStoreDomain",
  async (data, thunkAPI) => {
    try {
      const response = await storeApi.getStoreDomain();
      // console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);
