import { createSlice } from "@reduxjs/toolkit";
import {
  __login,
  __changePwd,
  __getAccount,
  __putAccount,
  __autoLogin,
} from "../async/login";
import jwt from "jwt-decode";

const initialState = {
  userInfo: { email: null, userId: null },
  account: {
    email: null,
    name: null,
    phone: null,
    brand: null,
  },
  isFetching: false,
  errorMessage: null,
  loginResult: null,
  brand: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    _loginCheck: state => {
      if (localStorage.getItem("email")) {
        state.userInfo.email = localStorage.getItem("email");
        state.userInfo.userId = Number(localStorage.getItem("userId"));
        return;
      } else {
        state.userInfo.email = null;
        window.location.replace("/login");
        return;
      }
    },
    _logoutUser: state => {
      state.userInfo = { nickname: null, userId: null };
      state.loginDone = false;
      localStorage.clear();
    },
    _changeBrand: state => {
      const accessToken = localStorage.getItem("accessToken");
      const JWTAccess = jwt(accessToken);
      state.brand = JWTAccess.brand;
    },
  },
  extraReducers: builder =>
    builder
      // 로그인
      .addCase(__login.fulfilled, (state, action) => {
        localStorage.setItem("email", action.payload.member.email);
        state.userInfo.email = action.payload.member.email;
        state.isFetching = false;
        state.errorMessage = null;
        state.loginResult = true;
      })
      .addCase(__login.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__login.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
        state.loginResult = false;
      })
      // 비밀번호 변경
      .addCase(__changePwd.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
        state.loginResult = true;
      })
      .addCase(__changePwd.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__changePwd.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
        state.loginResult = false;
      })
      // 계정조회
      .addCase(__getAccount.fulfilled, (state, action) => {
        state.account = action.payload.member;
        state.isFetching = false;
        state.errorMessage = null;
        state.loginResult = true;
      })
      .addCase(__getAccount.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getAccount.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
        state.loginResult = false;
      })
      // 계정수정
      .addCase(__putAccount.fulfilled, (state, action) => {
        localStorage.setItem("accessToken", action.payload.member.accessToken);
        state.account = action.payload.member;
        state.isFetching = false;
        state.errorMessage = null;
        state.loginResult = true;
      })
      .addCase(__putAccount.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__putAccount.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
        state.loginResult = false;
      })
      // 자동로그인
      .addCase(__autoLogin.fulfilled, (state, action) => {
        state.userInfo.email = action.payload.member.email;
        localStorage.setItem("email", action.payload.member.email);
        localStorage.setItem("accessToken", action.payload.member.accessToken);
        localStorage.setItem(
          "refreshToken",
          action.payload.member.refreshToken,
        );
        state.isFetching = false;
        state.errorMessage = null;
        state.loginResult = true;
      })
      .addCase(__autoLogin.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__autoLogin.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
        state.loginResult = false;
      }),
});

export const { _loginCheck, _logoutUser, _changeBrand } = loginSlice.actions;
export default loginSlice.reducer;
