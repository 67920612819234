import { createAsyncThunk } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { customerApi } from "../../shared/api";

export const __getCustomer = createAsyncThunk(
  "get/getCustomerList",
  async (data, thunkAPI) => {
    try {
      const res = await customerApi.getCustomerList(data.type, data.value);
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      } else {
        Swal.fire("목록 불러오기 실패", "네트워크 연결 확인", "error");
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __getBlockCustomer = createAsyncThunk(
  "get/getBlockCustomerList",
  async (data, thunkAPI) => {
    try {
      const res = await customerApi.getBlockCustomerList();
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      } else {
        Swal.fire("목록 불러오기 실패", "네트워크 연결 확인", "error");
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __setBlockCustomer = createAsyncThunk(
  "post/setBlockCustomer",
  async (memberId, { rejectWithValue }) => {
    const body = { memberId };
    try {
      const res = await customerApi.setBlockCustomer(body);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const __deleteBlockCustomer = createAsyncThunk(
  "delete/deleteBlockCustomer",
  async (memberId, { rejectWithValue }) => {
    const body = {
      memberId,
    };
    try {
      const res = await customerApi.deleteBlockCustomer(body);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      }
      return rejectWithValue(err.response.data);
    }
  },
);
