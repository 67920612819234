import { createAsyncThunk } from "@reduxjs/toolkit";
import { serviceApi } from "../../shared/api";
import Swal from "sweetalert2";

export const __getNotice = createAsyncThunk(
  "get/getNotice",
  async (data, thunkAPI) => {
    try {
      const res = await serviceApi.getNotice(data);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __getFaq = createAsyncThunk(
  "get/getFaq",
  async (data, thunkAPI) => {
    try {
      const res = await serviceApi.getFaq(data);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);
