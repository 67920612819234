import { createSlice } from "@reduxjs/toolkit";
import { __getFaq, __getNotice } from "../async/service";

const initialState = {
  isFetching: false,
  errorMessage: null,
  notice: [],
  noticeCount: 0,
  faq: [],
  faqCount: 0,
  messagesId: [],
};

const serviceSlice = createSlice({
  name: "service",
  initialState: initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(__getNotice.fulfilled, (state, action) => {
        console.log(action.payload);
        state.notice = action.payload.information.rows;
        state.noticeCount = action.payload.information.count;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getNotice.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getNotice.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getFaq.fulfilled, (state, action) => {
        state.faq = action.payload.question.rows;
        state.faqCount = action.payload.question.count;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getFaq.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getFaq.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      }),
});

export const {} = serviceSlice.actions;
export default serviceSlice.reducer;
