import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginApi } from "../../shared/api";
import Swal from "sweetalert2";

// 로그인
export const __login = createAsyncThunk(
  "post/login",
  async (data, thunkAPI) => {
    try {
      const response = await loginApi.login(data);
      console.log(response);
      if (response.status === 201) {
        localStorage.setItem("accessToken", response.data.member.accessToken);
        localStorage.setItem("refreshToken", response.data.member.refreshToken);
        // window.location.replace("/signupFinish");
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 비밀번호 변경
export const __changePwd = createAsyncThunk(
  "post/changePwd",
  async (data, thunkAPI) => {
    try {
      const response = await loginApi.changePwd(data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// 계정조회
export const __getAccount = createAsyncThunk(
  "get/getAccount",
  async (data, thunkAPI) => {
    try {
      const res = await loginApi.getAccount();
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

// 계정수정
export const __putAccount = createAsyncThunk(
  "put/putAccount",
  async (data, { rejectWithValue }) => {
    try {
      const res = await loginApi.putAccount(data);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

// 자동로그인
export const __autoLogin = createAsyncThunk(
  "post/autoLogin",
  async (data, thunkAPI) => {
    try {
      const response = await loginApi.autoLogin(data);
      if (response.status === 201) {
        return response.data;
      }
    } catch (err) {
      Swal.fire(
        "일시적인 오류가 발생하였습니다.",
        `잠시 후 다시 시도해 주세요.`,
        "error",
      );
      console.error();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);
