import { createSlice } from "@reduxjs/toolkit";
import {
  __getCustomer,
  __getBlockCustomer,
  __setBlockCustomer,
  __deleteBlockCustomer,
} from "../async/customer";

const initialState = {
  isFetching: false,
  errorMessage: null,
  messages: [],
  messagesId: [],
  customerList: [],
  blockCustomer: [],
  updateId: "",
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    _deleteBlock: (state, action) => {
      const list = state.blockCustomer.filter(v => {
        return v.memberId === action.payload ? false : true;
      });
      state.blockCustomer = list;
    },
    _updateBlock: (state, action) => {
      state.blockCustomer.push(action.payload);
    },
  },
  extraReducers: builder =>
    builder
      // 고객 전체 목록 검색
      .addCase(__getCustomer.fulfilled, (state, action) => {
        state.customerList = action.payload.customer;
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getCustomer.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getCustomer.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      // 차단 고객 목록
      .addCase(__getBlockCustomer.fulfilled, (state, action) => {
        state.blockCustomer = action.payload.member;
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getBlockCustomer.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getBlockCustomer.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      // 차단 고객 등록
      .addCase(__setBlockCustomer.fulfilled, (state, action) => {
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__setBlockCustomer.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__setBlockCustomer.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      // 차단 고객 삭제
      .addCase(__deleteBlockCustomer.fulfilled, (state, action) => {
        state.messages = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__deleteBlockCustomer.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__deleteBlockCustomer.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      }),
});

export const { _deleteBlock, _updateBlock } = customerSlice.actions;
export default customerSlice.reducer;
