import { createAsyncThunk } from "@reduxjs/toolkit";
import { eventApi } from "../../shared/api";
import Swal from "sweetalert2";

export const __getEvent = createAsyncThunk(
  "get/getEvent",
  async (data, thunkAPI) => {
    try {
      const res = await eventApi.getEvent();
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __getEventDue = createAsyncThunk(
  "get/getEventDue",
  async (page, thunkAPI) => {
    try {
      const res = await eventApi.getEventDue(page);
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          "잠시 후 다시 시도해 주세요.",
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __getEventDone = createAsyncThunk(
  "get/getEventDone",
  async (page, thunkAPI) => {
    try {
      const res = await eventApi.getEventDone(page);
      console.log(res);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __postEvent = createAsyncThunk(
  "post/postEvent",
  async (data, { rejectWithValue }) => {
    try {
      const res = await eventApi.postEvent(data);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const __putEvent = createAsyncThunk(
  "put/putEvent",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      // console.log(data.data.coupons.length);

      // if (data.data.coupons.length !== 0) {
      //   for (let i = 0; i < data.data.coupons.length; i++) {
      //     console.log(
      //       moment(Date.parse(data.data.coupons[i].startExpDate))
      //         .add(-9, "h")
      //         .format("YYYY-MM-DD HH:mm:ss"),
      //     );
      //     console.log(data.data.coupons[i].startExpDate);

      //     data.data.coupons[i].startExpDate = moment(
      //       Date.parse(data.data.coupons[i].startExpDate),
      //     )
      //       .add(-9, "h")
      //       .format("YYYY-MM-DD HH:mm:ss");
      //     data.data.coupons[i].endExpDate = moment(
      //       Date.parse(data.data.coupons[i].endExpDate),
      //     )
      //       .add(-9, "h")
      //       .format("YYYY-MM-DD HH:mm:ss");
      //   }
      // }
      const res = await eventApi.putEvent(data);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const __deleteEvent = createAsyncThunk(
  "delete/deleteEvent",
  async (eventId, { rejectWithValue }) => {
    try {
      const res = await eventApi.deleteEvent(eventId);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const __getEventCoupon = createAsyncThunk(
  "get/getEventCoupon",
  async (eventIdx, { rejectWithValue }) => {
    try {
      const res = await eventApi.getEventCoupon(eventIdx);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __getEventNotice = createAsyncThunk(
  "get/getEventNotice",
  async (eventIdx, { rejectWithValue }) => {
    try {
      const res = await eventApi.getEventNotice(eventIdx);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __postEventCoupon = createAsyncThunk(
  "post/postEventCoupon",
  async (data, { rejectWithValue }) => {
    try {
      const res = await eventApi.postEventCoupon(data);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __putEventCoupon = createAsyncThunk(
  "put/putEventCoupon",
  async (data, { rejectWithValue }) => {
    try {
      const res = await eventApi.putEventCoupon(data);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __deleteEventCoupon = createAsyncThunk(
  "delete/deleteEventCoupon",
  async (data, { rejectWithValue }) => {
    try {
      const res = await eventApi.deleteEventCoupon(data);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __getPromo = createAsyncThunk(
  "get/getPromo",
  async (data, { rejectWithValue }) => {
    try {
      const res = await eventApi.getPromo();
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __putPromo = createAsyncThunk(
  "put/putPromo",
  async (data, { rejectWithValue }) => {
    try {
      const res = await eventApi.putPromo(data.id, {
        promotion: data.promotion,
        guideline: data.guideline,
      });
      if (res.status === 202) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);

export const __getEventResult = createAsyncThunk(
  "get/getEventResult",
  async (brandIdx, { rejectWithValue }) => {
    try {
      const res = await eventApi.getEventResult(brandIdx);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);
