import { createSlice } from "@reduxjs/toolkit";
import {
  __getStore,
  __postStore,
  __putStore,
  __duplicate_domain,
  __getButton,
  __postButton,
  __editButton,
  __postInstallButton,
  __deleteButton,
  __getManagerList,
  __getCustomerList,
  __postManager,
  __deleteManager,
  __getProducts,
  __getMap,
  __getStoreDomain,
} from "../async/store";

const initialState = {
  progress: 1,
  fixColor: true,
  isFetching: false,
  errorMessage: null,

  checkNameDupLoading: false, // 도메인중복 체크 중
  checkNameDupDone: false, // 도메인중복 체크 중
  checkNameDupError: false, // 도메인중복 체크 중
  checkNameDupResult: true,

  prevDomain: "",
  storeData: {
    domain: "",
    style: "",
    filter: "",
    opacity: 0.0,
    logo: "",
  },
  storeDataTmp: {
    domain: "",
    style: "",
    filter: "",
    opacity: 0.0,
    logo: "",
  },
  index: 0,
  btnData: {
    filter: null,
    opacity: 0,
    left: 0,
    bottom: 0,
    logoIdx: 1,
  },
  nextState: false,
  putStyleData: "",
  putFilterData: "",
  putOpacityData: 0,
  putLogoData: "",
  manager: [],
  customerList: [],
  products: [],
  selectedProducts: [],
  saveSelectedProducts: [],
  selectedContents: [],
  saveSelectedContents: [],
  eventContents: [],
  eventMap: [],
  storeDomain: "",
  btnInstallProgress: 0,
};

const storeSlice = createSlice({
  name: "store",
  initialState: initialState,
  reducers: {
    _setNextProgress: (state, action) => {
      state.progress = Number(window.location.href.slice(-1));
      state.progress++;
      window.location.href = `/storeCreate/${state.progress}`;
    },
    _setPrevProgress: (state, action) => {
      state.progress = Number(window.location.href.slice(-1));
      state.progress--;
      window.location.href = `/storeCreate/${state.progress}`;
    },
    _setDomain: (state, action) => {
      state.storeData.domain = action.payload;
    },
    _setStyle: (state, action) => {
      state.storeData.style = action.payload;
    },
    _setFilter: (state, action) => {
      state.storeData.filter = action.payload;
    },
    _setOpacity: (state, action) => {
      state.storeData.opacity = action.payload;
    },
    _setLogo: (state, action) => {
      state.storeData.logo = action.payload;
    },
    _setIdx: (state, action) => {
      state.index = action.payload;
    },
    _setNextState: (state, action) => {
      state.nextState = action.payload;
    },
    _setPutStyle: (state, action) => {
      state.putStyleData = action.payload;
    },
    _setPutFilter: (state, action) => {
      state.putFilterData = action.payload;
    },
    _setPutOpacity: (state, action) => {
      state.putOpacityData = action.payload;
    },
    _setPutLogo: (state, action) => {
      state.putLogoData = action.payload;
    },
    _setBtnStyle: (state, action) => {
      state.btnData = action.payload;
    },
    _updateManager: (state, action) => {
      action.payload.forEach(manager => {
        state.manager.push(manager);
      });
    },
    _deleteManager: (state, action) => {
      const list = state.manager.filter(v => {
        return v.id === action.payload ? false : true;
      });
      state.manager = list;
    },
    _setSelectedProd: (state, action) => {
      state.selectedProducts = action.payload;
    },
    _setOneProd: (state, action) => {
      // state.selectedProducts[action.payload.key - 1] = action.payload;
      state.selectedProducts.splice(action.payload.key - 1, 1, action.payload);
    },
    _deleteSelectedProd: (state, action) => {
      // state.saveSelectedProducts.splice(action.payload, 1);
      state.selectedProducts.splice(action.payload, 1, 0);
      // state.saveSelectedProducts[action.payload] = 0;
      // state.selectedProducts[action.payload] = 0;
    },
    _deleteAllSelectedProd: (state, action) => {
      state.selectedProducts = [];
      state.saveSelectedProducts = [];
    },
    _deleteAllSelectedCon: (state, action) => {
      state.selectedContents = [];
      state.saveSelectedContents = [];
    },
    _setNullProd: (state, action) => {
      state.selectedProducts = Array.apply(
        null,
        new Array(action.payload.product),
      ).map(Number.prototype.valueOf, 0);
      state.selectedContents = Array.apply(
        null,
        new Array(action.payload.contents),
      ).map(Number.prototype.valueOf, 0);
    },
    _saveProducts: (state, action) => {
      state.saveSelectedProducts = action.payload;
    },
    _deleteNotSave: (state, action) => {
      state.selectedProducts = [];
      state.selectedContents = [];
    },
    _setSelectedCon: (state, action) => {
      state.selectedContents.splice(action.payload.key - 1, 1, action.payload);
    },
    _saveContents: (state, action) => {
      state.saveSelectedContents = action.payload;
    },
    _deleteSelectedCon: (state, action) => {
      // state.saveSelectedContents.splice(action.payload, 1);
      state.selectedContents.splice(action.payload, 1, 0);

      // state.saveSelectedContents[action.payload] = 0;
      // state.selectedContents[action.payload] = 0;
    },
    _updateSelectedProdImg: (state, action) => {
      state.selectedProducts[action.payload.key - 1] = action.payload;
    },
    _setBtnInstallProgress: (state, action) => {
      state.btnInstallProgress = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(__getStore.fulfilled, (state, action) => {
        if (action.payload.store === null) {
          window.location.replace("/storeCreate");
          console.log("fail");
        }
        state.storeDataTmp = action.payload.store;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getStore.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getStore.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__postStore.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postStore.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__postStore.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__putStore.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__putStore.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__putStore.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__duplicate_domain.fulfilled, (state, action) => {
        state.checkNameDupLoading = false;
        state.checkNameDupDone = true;
        state.checkNameDupResult = true;
        state.nextState = true;
      })
      .addCase(__duplicate_domain.pending, (state, action) => {
        state.checkNameDupLoading = true;
        state.checkNameDupDone = false;
        state.checkNameDupError = null;
        state.nextState = false;
      })
      .addCase(__duplicate_domain.rejected, (state, action) => {
        state.checkNameDupLoading = false;
        state.checkNameDupError = action.payload;
        state.nextState = false;

        if (action.payload.error.domain === "Invalid value") {
          state.checkNameDupResult = "invalid";
        } else if (
          action.payload.error.message === "This domain already in use."
        ) {
          state.checkNameDupResult = false;
        }
      })
      .addCase(__getButton.fulfilled, (state, action) => {
        if (action.payload.button) {
          state.btnData = action.payload.button;
          localStorage.setItem("btnInstall", action.payload.button.installed);
        } else {
          state.btnData = {};
        }
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getButton.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getButton.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      .addCase(__postButton.fulfilled, (state, action) => {
        state.btnData = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postButton.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__postButton.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      .addCase(__postInstallButton.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postInstallButton.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__postInstallButton.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      .addCase(__editButton.fulfilled, (state, action) => {
        state.btnData = action.payload.button;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__editButton.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__editButton.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      .addCase(__deleteButton.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__deleteButton.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__deleteButton.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      .addCase(__getManagerList.fulfilled, (state, action) => {
        state.manager = action.payload.event;

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getManagerList.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getManagerList.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getCustomerList.fulfilled, (state, action) => {
        state.customerList = action.payload.event;

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getCustomerList.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getCustomerList.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__postManager.fulfilled, (state, action) => {
        // state.manager = action.payload.event;

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postManager.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__postManager.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__deleteManager.fulfilled, (state, action) => {
        if (action.payload.message === "OK") {
          state.isFetching = false;
          state.errorMessage = null;
        }
      })
      .addCase(__deleteManager.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__deleteManager.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getProducts.fulfilled, (state, action) => {
        state.products = action.payload.store.products;

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getProducts.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getProducts.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getMap.fulfilled, (state, action) => {
        state.eventMap = action.payload.store;

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getMap.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getMap.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      })
      .addCase(__getStoreDomain.fulfilled, (state, action) => {
        state.storeDomain = "https://" + action.payload.store.baseDomain;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getStoreDomain.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getStoreDomain.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.errorMessage;
      }),
});

export const {
  _setNextProgress,
  _setPrevProgress,
  _setDomain,
  _setFilter,
  _setLogo,
  _setStyle,
  _setOpacity,
  _setIdx,
  _setNextState,
  _setPutLogo,
  _setPutFilter,
  _setPutOpacity,
  _setPutStyle,
  _setBtnStyle,
  _updateManager,
  _deleteManager,
  _setSelectedProd,
  _deleteSelectedProd,
  _deleteAllSelectedProd,
  _setOneProd,
  _setNullProd,
  _saveProducts,
  _deleteNotSave,
  _deleteAllSelectedCon,
  _setSelectedCon,
  _saveContents,
  _deleteSelectedCon,
  _updateSelectedProdImg,
  _setBtnInstallProgress,
} = storeSlice.actions;
export default storeSlice.reducer;
