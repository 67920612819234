import { createSlice } from "@reduxjs/toolkit";
import {
  __getCategory,
  __getDetail,
  __postDetail,
  __putDetail,
  __getAllProducts,
  __getEventProducts,
} from "../async/product";

const initialState = {
  isFetching: false,
  errorMessage: null,
  detail: {},
  fileList: [],
  category: [],
  products: [],
  categoryProducts: [],
  eventProducts: [],
  eventContents: [],
};

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    _setFileList: (state, action) => {
      state.fileList = action.payload;
    },
    _deleteDetail: (state, action) => {
      state.detail = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(__getDetail.fulfilled, (state, action) => {
        state.detail = action.payload.product;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getDetail.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getDetail.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
      })
      .addCase(__postDetail.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postDetail.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__postDetail.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
      })
      .addCase(__putDetail.fulfilled, (state, action) => {
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__putDetail.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__putDetail.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
      })
      .addCase(__getCategory.fulfilled, (state, action) => {
        state.category = action.payload.store.categories;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getCategory.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getCategory.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
      })
      .addCase(__getAllProducts.fulfilled, (state, action) => {
        if (
          action.meta.arg === undefined ||
          typeof action.meta.arg === "undefined"
        ) {
          state.category = action.payload.product.categories;
          state.products = action.payload.product.products;
        } else {
          state.categoryProducts = action.payload.product;
        }

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getAllProducts.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getAllProducts.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
      })
      .addCase(__getEventProducts.fulfilled, (state, action) => {
        state.eventProducts = action.payload.product.products;
        state.eventContents = action.payload.product.contents;

        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__getEventProducts.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__getEventProducts.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
      }),
});

export const { _setFileList, _deleteDetail } = productSlice.actions;
export default productSlice.reducer;
