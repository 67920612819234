import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import loadable from "@loadable/component";
import GlobalStyle from "./shared/GlobalStyle";
import Loader from "./elements/Loader";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _loginCheck } from "./redux/modules/loginSlice";

function App() {
  const dispatch = useDispatch();
  const { email = null, userId = null } = useSelector(
    state => state.login.userInfo,
  );
  useEffect(() => {
    dispatch(_loginCheck);
  }, [dispatch, email]);

  // 코드 스플리팅을 위한 loadable설정
  const Login = loadable(() => import("./pages/Login"), {
    fallback: <Loader />,
  });
  const Signup = loadable(() => import("./pages/Signup"), {
    fallback: <Loader />,
  });
  const Chat = loadable(() => import("./pages/Chat"), {
    fallback: <Loader />,
  });
  const SignupFinish = loadable(
    () => import("./components/signupComponents/SignupFinish"),
    {
      fallback: <Loader />,
    },
  );
  const StoreCreate = loadable(() => import("./pages/StoreCreate"), {
    fallback: <Loader />,
  });

  const Event = loadable(() => import("./pages/Event"), {
    fallback: <Loader />,
  });

  const Home = loadable(() => import("./pages/Home"), {
    fallback: <Loader />,
  });

  const Service = loadable(() => import("./pages/Service"), {
    fallback: <Loader />,
  });

  const Store = loadable(() => import("./pages/Store"), {
    fallback: <Loader />,
  });

  const Account = loadable(() => import("./pages/Account"), {
    fallback: <Loader />,
  });

  const PasswordReset = loadable(() => import("./pages/PasswordReset"), {
    fallback: <Loader />,
  });

  const Preview = loadable(
    () => import("./components/storeCreateComponents/Preview"),
    {
      fallback: <Loader />,
    },
  );

  const Redirect = loadable(() => import("./pages/Redirect"), {
    fallback: <Loader />,
  });

  const AutoLogin = loadable(() => import("./pages/AutoLogin"), {
    fallback: <Loader />,
  });

  const ButtonInstall = loadable(() => import("./pages/ButtonInstall"), {
    fallback: <Loader />,
  });

  // const Test = loadable(() => import("./pages/Test"), {
  //   fallback: <Loader />,
  // });

  return (
    <Fragment>
      <GlobalStyle />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/token" element={<Redirect />} />
        <Route path="/chat/*" element={<Chat />} />
        <Route path="/signupFinish" element={<SignupFinish />} />
        <Route path="/storeCreate/*" element={<StoreCreate />} />
        <Route path="/" element={<Home />} />
        <Route path="/event/*" element={<Event />} />
        <Route path="/service/*" element={<Service />} />
        <Route path="/store/*" element={<Store />} />
        <Route path="/account/*" element={<Account />} />
        <Route path="/passwordReset/*" element={<PasswordReset />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="/auto" element={<AutoLogin />} />
        <Route path="/buttonInstall" element={<ButtonInstall />} />
        {/* <Route path="/test" element={<Test />} /> */}

        {/* <Route path="*" element={<Start />} /> */}
      </Routes>
    </Fragment>
  );
}

export default App;
