import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatApi } from "../../shared/api";
import Swal from "sweetalert2";

// 채팅 조회하기
export const __getChat = createAsyncThunk(
  "get/chat",
  async (user_id, thunkAPI) => {
    try {
      const response = await chatApi.getChat(user_id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      } else {
        Swal.fire(
          "채팅을 불러오는 데 실패했습니다.",
          "네트워크 연결 상태를 확인해주세요.!",
          "error",
        );
      }
      console.log(err);
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __getChatId = createAsyncThunk(
  "get/chatId",
  async (user_id, thunkAPI) => {
    try {
      const response = await chatApi.getChatId(user_id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      } else {
        Swal.fire(
          "채팅을 불러오는 데 실패했습니다.",
          "네트워크 연결 상태를 확인해주세요.!",
          "error",
        );
      }
      console.log(err);
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __getChatUnique = createAsyncThunk(
  "get/chatUnique",
  async (data, thunkAPI) => {
    try {
      console.log("test22");
      const response = await chatApi.getChatUnique();
      if (response.status === 200) {
        console.log(response);
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      } else {
        Swal.fire(
          "채팅방 목록을 불러오는 데 실패했습니다.",
          "네트워크 연결 상태를 확인해주세요.!",
          "error",
        );
      }
      console.log(err);
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __getBannedChat = createAsyncThunk(
  "get/bannedChat",
  async (data, thunkAPI) => {
    try {
      const response = await chatApi.getBannedChat();
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      } else {
        Swal.fire(
          "목록을 불러오는 데 실패했습니다.",
          "네트워크 연결 상태를 확인해주세요.!",
          "error",
        );
      }
      console.log(err);
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);

export const __putBannedChat = createAsyncThunk(
  "put/bannedChat",
  async (data, { rejectWithValue }) => {
    const body = {
      filter: data,
    };
    try {
      const res = await chatApi.putBannedChat(body);
      return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire("호출 회수 초과", `잠시 후 다시 진행해 주세요.`, "error");
      }
      return rejectWithValue(err.response.data);
    }
  },
);
