import { createAsyncThunk } from "@reduxjs/toolkit";
import { resultApi } from "../../shared/api";
import Swal from "sweetalert2";

// 이벤트 결과 조회하기
export const __postResult = createAsyncThunk(
  "post/result",
  async (event_id, thunkAPI) => {
    try {
      const response = await resultApi.postResult(event_id);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      } else {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `네트워크를 확인해주세요.`,
          "error",
        );
      }
      console.error();
      return thunkAPI.rejectWithValue(err.response.msg);
    }
  },
);
