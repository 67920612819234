import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeDataSave: {
    domain: "",
    style: "",
    filter: "",
    opacity: 0,
    logo: "",
  },
  queryData: "",
};

const localSlice = createSlice({
  name: "local",
  initialState: initialState,
  reducers: {
    _storeDataSave: (state, action) => {
      state.storeDataSave = action.payload;
    },
    _postQueryData: (state, action) => {
      state.queryData = action.payload;
    },
  },
});

export const { _storeDataSave, _postQueryData } = localSlice.actions;
export default localSlice.reducer;
