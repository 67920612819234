import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import chatReducer from "./modules/chatSlice";
import resultReducer from "./modules/resultSlice";
import loginReducer from "./modules/loginSlice";
import signupReducer from "./modules/signupSlice";
import storeReducer from "./modules/storeSlice";
import eventReducer from "./modules/eventSlice";
import serviceReducer from "./modules/serviceSlice";
import customerReducer from "./modules/customerSlice";
import localReducer from "./modules/localSlice";
import tutorialReducer from "./modules/tutorialSlice";
import productReducer from "./modules/productSlice";
import youtubeReducer from "./modules/youtubeSlice";

const rootReducer = combineReducers({
  chat: chatReducer,
  result: resultReducer,
  login: loginReducer,
  signup: signupReducer,
  store: storeReducer,
  event: eventReducer,
  service: serviceReducer,
  customer: customerReducer,
  local: localReducer,
  tutorial: tutorialReducer,
  product: productReducer,
  youtube: youtubeReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["local"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk];

const env = process.env.NODE_ENV;

if (env === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: [...middlewares],
  devTools: env !== "production", // production 일 때는, devtools 사용 안하도록 설정
});

export default store;
