import { createAsyncThunk } from "@reduxjs/toolkit";
import { youtubeApi } from "../../shared/api";
import Swal from "sweetalert2";

export const __getDescription = createAsyncThunk(
  "get/getDescription",
  async (videoId, { rejectWithValue }) => {
    try {
      const res = await youtubeApi.getDescription(videoId);
      if (res.status === 200) return res.data;
    } catch (err) {
      if (err.response.status === 429) {
        Swal.fire(
          "일시적인 오류가 발생하였습니다.",
          `잠시 후 다시 시도해 주세요.`,
          "error",
        );
      }
      console.error();
      return rejectWithValue(err.response.data);
    }
  },
);
