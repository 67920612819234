import { createSlice } from "@reduxjs/toolkit";
import { __postResult } from "../async/result";

const initialState = {
  isFetching: false,
  errorMessage: null,
  resultState: [],
};

const resultSlice = createSlice({
  name: "result",
  initialState: initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(__postResult.fulfilled, (state, action) => {
        console.log(action.payload);
        state.resultState = action.payload;
        state.isFetching = false;
        state.errorMessage = null;
      })
      .addCase(__postResult.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(__postResult.rejected, (state, action) => {
        console.log(action.payload);
        state.isFetching = false;
        // state.errorMessage = action.errorMessage;
      }),
});

export const {} = resultSlice.actions;
export default resultSlice.reducer;
